#react-sidebar .sidebar-backdrop {
  cursor: pointer;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1011;
  visibility: hidden;
  opacity: 0;
  -webkit-transition: opacity 200ms ease-out;
  -moz-transition: opacity 200ms ease-out;
  -ms-transition: opacity 200ms ease-out;
  -o-transition: opacity 200ms ease-out;
  transition: opacity 200ms ease-out;
  background-color: rgba(41, 48, 59, 0.35);
}

#react-sidebar .sidebar-backdrop.show {
  visibility: visible;
  opacity: 1;
}

#react-sidebar .sidebar-main {
  width: 285px;
  height: 100vh;
  background-color: #fff;
  z-index: 1012;
  position: fixed;
  top: 0;
  left: 0;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  transform: translateX(-300px);
  transition: all 0.5s ease-in-out;
  overflow: hidden;
  overflow-y: auto;
}

#react-sidebar .sidebar-main.second {
  transform: translateX(600px);
  z-index: 1013;
  visibility: hidden;
}

#react-sidebar .sidebar-main.show {
  transform: translateX(0px);
  visibility: visible;
}

#react-sidebar .sidebar-main-content {
  width: 100%;
  display: flex;
  flex-direction: column;
}

#react-sidebar .sidebar-header {
  background-color: #f3f3f3;
  font-size: 1.5rem;
  font-family: inherit;
  font-weight: 600;
  display: flex;
  align-items: center;
  padding: 1rem 0.8rem;
  color: #5e5b5b;
}

#react-sidebar .section-heading {
  font-size: 0.78rem;
  font-weight: 700;
  color: #3c3b37;
  padding: 0.5rem 1rem;
  text-transform: uppercase;
  letter-spacing: 0.3px;
  display: flex;
  align-items: center;

  /* font-weight: 700;
  color: #3c3b37;
  padding: 0.5rem 0.8rem;
  font-size: 12px;
  color: #686f7a;
  text-transform: uppercase;
  letter-spacing: 1px; */
}

#react-sidebar .section-heading .text {
  margin-left: 6px;
}

#react-sidebar .section-heading.border {
  border-top: 1px solid #dcdacb;
  margin-top: 0.8rem;
}

.sidebar-main-content ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.sidebar-main-content ul li {
  padding: 0.5rem 0.8rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #3c3b37;
}
.sidebar-main-content ul li:hover {
  cursor: pointer;
  background-color: #f3f3f3;
}

.sidebar-main-content ul li.disabled {
  opacity: 0.6;
  pointer-events: none;
}

.sidebar-main-content ul li i {
  margin-right: 8px;
  font-size: 17px;
}

.sidebar-main-content ul a {
  color: inherit;
  text-decoration: initial;
}

.first-back-btn {
  color: #767c87;
  background-color: #f2f3f5;
  padding: 1.2rem;
  font-weight: 500;
}

.first-back-btn span,.second-back-btn span{
  margin-left: 3px;
}

.first-back-btn:hover,
.second-back-btn:hover {
  cursor: pointer;
}

.first-back-btn i,
.second-back-btn i {
  margin-right: 8px;
}

.second-back-btn {
  background-color: #fff;
  border-bottom: 1px solid #dcdacb;
  margin-bottom: 0.8rem;
  color: #767c87;
  padding: 1.2rem;
  font-weight: 500;
}

.section-seprator {
  margin-left: 16px;
  margin-right: 16px;
  /* margin: 0; */
  background-color: #dedfe0;
  box-sizing: content-box;
  height: 0;
  /* margin-top: 21px; */
  /* margin-bottom: 21px; */
  border: 0;
  border-top: 1px solid #e8e9eb;
}

.flex-align-center {
  display: flex;
  align-items: center;
}
